import "../App.css";
import Aditya from "../assets/Aditya.jpeg";
import { ReactComponent as LinkedIn } from "../assets/linkedin.svg";
import Subbu from "../assets/subbu-1.jpeg";
import { ReactComponent as Twitter } from "../assets/twitter.svg";
import { ReactComponent as Youtube } from "../assets/youtube.svg";
import AmazeAnimation from "../components/AmazeAnimation";
import { AppCard } from "../components/AppCard";
import HelmetInfo from "../components/HelmetInfo";
import APPS from "../data/apps";
import Logo from "../logo.png";

export default function Home() {
  const allApps = Object.values(APPS);
  return (
    <div className="container mx-auto text-white items-center justify-center">
      <HelmetInfo title={"Team Black Box"} description={"Team Black Box is a bootstrapped startup building useful software and hardware"} />
      <div
        className="flex flex-col items-center justify-center px-4 gap-4 sm:px-16 2xl:px-48 font-mono"
      >
        <div className="flex flex-col md:flex-row items-center justify-center gap-4 px-8 pt-12 select-none">
          <img
            src={Logo}
            className="h-10 w-10 border border-white rounded"
            alt={"Team Black Box Logo"}
          />
          <div className="text-xl md:text-4xl font-bungee text-center">
            Team Black Box
          </div>
        </div>
        <p className="max-w-[768px] text-center">
          We are a bootstrapped startup building useful software and hardware. We call it "Building the Amaze!" :)
        </p>
        <div className="font-bungee text-lg md:text-3xl">
          Our Products
        </div>
        <div className="flex flex-col md:flex-row gap-2 items-start">
          {allApps.map((app) => 
              <AppCard key={app.id} app={app} />)}
        </div>
        <div className="font-bungee text-lg md:text-3xl">
            Founders
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-16 mt-4 justify-center items-center">
          <div className="flex flex-col items-center gap-2 w-96 text-center">
            <img
              src={Subbu}
              alt="Subbu"
              className="grayscale w-64 h-64 rounded-full"
            />
            <div className="font-bungee">Subramanian Elavathur</div>
            <div>Co-Founder Team Black Box</div>
            <div>Previously VP at Goldman Sachs</div>
            <div>Has been building websites for over 10 years</div>
            <div>Also builds model rc aircrafts and plays the guitar</div>
            <div className="flex flex-row gap-2">
            <a
              href="https://twitter.com/evsubr"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter className="h-8 w-8" />
            </a>
            <a
              href="https://www.linkedin.com/in/subramanian-elavathur/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedIn className="h-8 w-8" />
            </a>
            <a
              href="https://www.youtube.com/@subramanian-elavathur"
              target="_blank"
              rel="noreferrer"
            >
              <Youtube className="h-8 w-8" />
            </a>
            </div>
          </div>
          <div className="flex flex-col gap-2 items-center w-96 text-center">
            <img
              src={Aditya}
              alt="Aditya"
              className="grayscale w-64 h-64 rounded-full"
            />
            <div className="font-bungee">Aditya Shetty</div>
            <div>Co-Founder Team Black Box</div>
            <div>Previously at Amazon</div>
            <div>Has been building mobile apps for over 10 years</div>
            <div>Plays the Piano, Football, DOTA and Chess</div>
            <div className="flex flex-row gap-2">
            <a
              href="https://twitter.com/abs192"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter className="h-8 w-8" />
            </a>
            <a
              href="https://www.linkedin.com/in/aditya-shetty-318b09b6/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedIn className="h-8 w-8" />
            </a>
            </div>
          </div>
      </div>
      {/* <div
        className="flex flex-col px-4 gap-4 sm:px-16 2xl:px-48 py-8 text-xl font-mono"
        id="origins"
      >
        <div className="flex w-full gap-2 justify-center items-center mb-2">
          <div className="font-bungee text-lg md:text-3xl">
            Origins
          </div>
        </div>
        <div className="text-center">
          Here is the OG Team Black Box with Aria
          <span className="text-red-500"> ❤️</span>
        </div>
        <img
          src={Namaskara}
          alt="Team Black Box with Aria"
          className="grayscale mt-2"
        />
      </div> */}
      <AmazeAnimation />
      <div className="text-center">© 2022 TeamBlackBox Private Limited</div>
      
      <div className="text-center">
        TeamBlackBox Private Limited, 91 Springboard Business Hub Private Limited, 512/10 Service Lane Outer Ring Road, Mahadevapura, Bangalore, Karnataka, India, 560048
      </div>
      <div className="text-center pb-8">support @ teamblackbox.in</div>
    </div>
  );
}
